
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { AlertComponentInterface } from "../components/interfaces";
import { LazyLoad } from "./../components/LazyLoad";

type ResetStep = "forgot" | "verify" | "reset" | "complete" | "error";

@Component({
	components: {
		ForgotPasswordForm: () =>
			LazyLoad(import(/* webpackChunkName: "forgot-password.form" */ "./../components/forms/ForgotPasswordForm.vue")),
		ChallengeForm: () => LazyLoad(import(/* webpackChunkName: "challenge.form" */ "./../components/forms/ChallengeForm.vue")),
		VerifyForm: () => LazyLoad(import(/* webpackChunkName: "verify.form" */ "./../components/forms/VerifyForm.vue")),
		ResetPasswordForm: () =>
			LazyLoad(import(/* webpackChunkName: "reset-password.form" */ "./../components/forms/ResetPasswordForm.vue")),
		SuccessRedirect: () => LazyLoad(import(/* webpackChunkName: "success-redirect" */ "./../components/SuccessRedirect.vue")),
	},
})
export default class Forgot extends Vue {
	@Prop({ default: {} })
	public query!: Record<string, any>;

	@Prop({ required: true })
	public defaultCountry!: string;

	@Prop({ required: true })
	public countries!: Record<string, string>;

	public actions = {
		forgot: this.$url(process.env.VUE_APP_FORGOT_PASSWORD_ACTION as string, this.query),
		reset: this.$url(process.env.VUE_APP_RESET_PASSWORD_ACTION as string, this.query),
		verify: process.env.VUE_APP_CHALLENGE_VERIFY_ACTION,
	};

	@Ref("alert")
	public readonly $alert!: AlertComponentInterface;

	@Ref("success")
	public readonly $success!: InstanceType<any>;

	@Ref("forgotForm")
	public readonly $forgotForm!: InstanceType<any>;

	@Ref("verifyForm")
	public readonly $verifyForm!: InstanceType<any>;

	public step: ResetStep = "forgot";

	public result: Record<"begin" | "verification" | "complete", any> = {
		begin: {},
		verification: {},
		complete: {},
	};

	created() {
		console.log("Return url", this.returnUrl);

		console.log("Return url is internal? ", this.$isInternalUrl(this.returnUrl));
	}

	onChallengeCancel() {
		this.step = "forgot";
		this.$forgotForm.recreatePuzzle();
	}

	onChallengeRetry() {
		this.$forgotForm.resubmit();
	}

	get signinUrl() {
		return this.$url(process.env.VUE_APP_SIGNIN_URL as string, this.query);
	}

	get returnUrl() {
		return this.query.continue ?? (process.env.VUE_APP_SIGNIN_URL as string);
	}

	get verifyTitle() {
		switch (this.result.begin.mode) {
			case "phone":
				return "Verify Your Phone Number";

			case "email":
				return "Verify Your Email";

			default:
				return "Verify Account";
		}
	}

	get metadata() {
		return {
			key: this.result.begin.key,
			principal: this.result.begin.principal,
			// eslint-disable-next-line @typescript-eslint/camelcase
			challenge_id: this.result.begin.challenge.cid,
			// eslint-disable-next-line @typescript-eslint/camelcase
			verification_token: this.result.verification.token,
		};
	}

	onForgotDone({ data }: any) {
		this.result.begin = data;
		this.step = "verify";
	}

	onVerificationDone({ data }: any) {
		this.result.verification = data;
		this.step = "reset";
	}

	onResetDone() {
		this.step = "complete";
		this.$success.count();
	}
}
